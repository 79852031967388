/*!
Theme Name: UHC USWDS
Theme URI: https://blaineturner.com
Author: BlaineTurner Advertising
Author URI:  http://blaineturner.com/
Description: US Web Design Standards WP Theme
Version: 20180828
*/
@import "variables";
@import "animate";
@import "grid";
@import "spinner";
@import "./node_modules/uswds/src/stylesheets/uswds";
//////////////////////////////
// global theme mixins
//////////////////////////////
@mixin BeforeSlash( $color: #ecaa00 ) {
    position: relative;
    padding-left: 90px;
    &:before {
        content: ' ';
        position: absolute;
        width: 30px;
        height: 60px;
        left: 30px;
        top: 50%;
        transform: translateY(-50%) skew(-33.75deg);
        background: $color;
    }
}
.gold-slash {
    @include BeforeSlash();
    color: $uhc-blue;
    line-height: 1;
}
.blue-slash {
    @include BeforeSlash(#002855);
}
.white-slash {
    @include BeforeSlash(#fff);
}
$bulletcolor: #ecaa00;
$bulletcontent: '\f0c8';
@mixin StyledList($bulletcolor: #ecaa00, $bulletcontent: '\f0c8') {
    list-style: none;
    list-style-position: outside;
    margin-top: 0;
    padding-left: 1rem;
    li {
        line-height: 1.2;
        padding-bottom: 12px;
        position: relative;
        padding-left: 18px;
        border-bottom: 1px solid #d8d8d8;
        &:before {
            position: absolute;
            font-family: FontAwesome;
            left: -1rem;
            font-size: 16px;
            top: 2px;
            content: $bulletcontent;
            color: $bulletcolor;
        }
    }
}
ul.styled {
    @include StyledList;
}
//////////////////////////////
// site fonts
//////////////////////////////
@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.eot');
    src: url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.eot?#iefix') format('embedded-opentype'),
         url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.ttf') format('truetype'),
         url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('fonts/helvetica_neue/HelveticaNeue-Light.eot');
    src: url('fonts/helvetica_neue/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
         url('fonts/helvetica_neue/HelveticaNeue-Light.ttf') format('truetype'),
         url('fonts/helvetica_neue/HelveticaNeue-Light.woff') format('woff');        font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('fonts/helvetica_neue/HelveticaNeue-Medium.eot');
    src: url('fonts/helvetica_neue/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
         url('fonts/helvetica_neue/HelveticaNeue-Medium.ttf') format('truetype'),
         url('fonts/helvetica_neue/HelveticaNeue-Medium.woff') format('woff');        font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Iowan Old Style Bold';
    src: url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.eot');
    src: url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.ttf') format('truetype'),
         url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.woff') format('woff');        font-weight: normal;
    font-style: normal;
}
.helvetica-neue-med {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: bold;
}
.iowanold-st-bld {
    font-family: 'Iowan Old Style Bold', Georgia, serif;
}
//////////////////////////////
// typography
//////////////////////////////
h1, h2, h3, h4, h5 {
	margin-top: 0;
}
.grey-text {
    color: #757575;
}
.text-center {
    text-align: center;
}
.text-larger {
    font-size: 1.5em;
}
@mixin line-break ( $color ) {
    width: 110px;
    background-color: $color;
    height: 3px;
    margin-bottom: 45px;
}
.blue-line {
    @include line-break($uhc-blue);
}
.gold-line {
    @include line-break($uhc-gold);
}
body {
    font-family: 'Helvetica Neue', helvetica,arial,sans-serif;
    font-size: 17px;
    line-height: 31px;
    color: $uhc-black;
}
h1.alt {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 44px;
    line-height: 55px;
    font-weight: normal;
    background-color: $uhc-black;
    color: $uhc-white;
}
h1 {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 58px;
    line-height: 1;
    font-weight: normal;
    color: $uhc-blue;
    letter-spacing:-2px;
      @media (max-width: $breakpoint-phone) {
        font-size: 38px;
        line-height: 1;
        letter-spacing: 0px;
        }
}
h2 {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 55px;
    color: #000;
    font-weight: 400;
    letter-spacing:-2px;
    line-height: 1;
    @media (max-width: $breakpoint-phone) {
        font-size:34px;
    }
}
h3 {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-size: 28px;
    line-height: 34px;
    color: $uhc-blue;
    font-weight: 300;
    @media (max-width: $breakpoint-phone) {
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0px;
    }
}
h4 {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-size: 30px;
    line-height: 37px;
    color: $uhc-black;
    font-weight: 700;
}
h5 {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-size: 28px;
    line-height: 34px;
    color: $uhc-black;
    font-weight: 400;
}
//////////////////////////////
// list styles
//////////////////////////////
.no-bullet {
    list-style-type: none;
    padding-left: 0;
}
.divider {
    @extend .no-bullet;
    li {
        border-bottom: 1px solid #ececec;
        padding-bottom: 1rem;
    }
}
html {
  scroll-behavior: smooth;
}
//////////////////////////////
// header
//////////////////////////////
.usa-header-basic {
	border-bottom: none;
	background-color: #fff;
	.usa-nav-container {
		max-width: 100%;
		height: $navbar-height;
	}
	.usa-navbar {
		height: $navbar-height;
		padding-left: 10px;
		padding-right: 10px;
		@media (max-width: #{$nav-width - 1}) {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
	        border-bottom: none;
		}
		.usa-logo {
			margin-top: 0px;
			margin-bottom: 0px;
			margin-left: 0px;
			width: 360px;
			@media (max-width: #{$nav-width - 1}) {
				max-width: calc(100% - 60px);
			}
			img {
				vertical-align: middle;
			    width: 100%;
			    height: auto;
			}
		}
	}
}
//////////////////////////////
// navbar
//////////////////////////////
nav.usa-nav {
	padding-top: 0px;
	padding-left: 0px;
	.usa-nav-inner {
		padding-left: 0;
		padding-right: 0;
	}
	.usa-nav-close {
		color: $color-primary;
		margin-bottom: 3em;
	}
	a {
		font-family: 'Helvetica Neue' !important;
		i.fa {
		    vertical-align: middle;
		}
	}
	.usa-nav-primary {
	    @media (max-width: $nav-width) {
	        button {
    	        &[aria-expanded=false] {
                    background-position: right 1em top 50%!important;
                }
    	    }
    	    li ul li a {
                background-color: $uhc-blue;
    	    }
    	    li > a {
	    		transition: 0.15s ease-in-out;
                &:hover {
                    background-color: #f1f1f1;
                    color: $uhc-blue!important;
                    transition: 0.05s ease-in-out;
                }
        	}
	    }
	}
	@media (min-width: $nav-width) {
		height: $navbar-height;
		display:flex;
		align-items: center;
		.usa-nav-primary {
			margin-top: 0;
			display: flex;
		    flex: 1;
		    align-items: center;
			li {
				position: relative;
			    transform: skew(-33.75deg, 0deg);
			    border-right: 1px solid #859cb3;
			    -webkit-font-smoothing: antialiased;
	    		padding: 0px 8px;
                transition: all 0.2s;
                min-width: 145px;
                text-align: center;
	    		&:nth-last-of-type(3) {
	    		    border-right: none !important;
	    		}
	    		&:hover {
	    			background-color: $uhc-gold;
	    			.usa-nav-submenu {
	    			    visibility: visible;
                        opacity: 1;
                        max-height: 300px;
                        -webkit-animation-duration: .3s;
                        animation-duration: .3s;
	    			}
	    		}
			    a {
			    	transition: background-color .3s;
				    font-size: 16px;
				    color: $uhc-blue;
				    display: block;
				    cursor: pointer;
				    padding: 16px;
			        transform: skew(33.75deg, 0deg);
			        letter-spacing: -0.25px;
			        &:hover {
			            span {
	    			        border: none !important;
			            }
                    }
			    }
			}
			li.phone-number {
			    border-right: none;
			    &:hover {
	                background-color: rgba(0,0,0,.1);
		        }
			    a {
			        position: relative;
			        padding-left: 77px;
			        font-size: 38px;
			        &:before {
			            content: ' ';
			            width: 45px;
			            height: 45px;
			            background-image: url('img/call-icon-gold.png');
			            position: absolute;
                        background-size: 100%;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%);
			        }
			    }
			}
			button {
                padding: 16px;
                color: $uhc-blue;
                font-size: 16px;
                font-weight: 700;
                line-height: 1;
                width: initial;
                letter-spacing: -0.25px;
                &[aria-expanded=false] {
                    background-position: right 1.5rem top 50%!important;
                }
                & + .usa-nav-submenu {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    background-color: #fff;
                    transition: .25s all ease-in-out;
                    top: 64px;
                    width: 190%;
                    z-index: -1;
                    overflow: hidden;
                    left: 0;
                    a {
                        text-align: left;
                        letter-spacing: 0.25px!important;
                    }
                }
                &[aria-expanded=true] + .usa-nav-submenu {
                    visibility: visible;
                    opacity: 1;
                }
			}
		}
	}
}
.usa-nav-submenu{
    background-color:#fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
    @media (min-width: $nav-width) {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        margin-left: 0em;
        padding-top: 0em!important;
        padding-bottom: 0em!important;
        width: 18.2rem;
        position: absolute;
        text-align: left;
    }
}
nav.usa-nav .usa-nav-primary li ul li{
    transform:none;
}
.usa-nav-primary a:hover{
    background:rgba(0,0,0,0);
}
nav ul li ul li {
    border-right: none;
    border-top: 1px solid #859cb3!important;
    &:nth-of-type(1){
        border-top:none!important;
    }
}
@media (min-width: $nav-width) {
    .usa-nav-primary button{
        transform: skew(33deg, 0);
    }
    .usa-nav-primary button[aria-expanded=true]{
        background:none!important;
        transform: skew(33deg, 0);
        span{
            border-bottom:none;
            color: #002a57;
        }
    }
}
.usa-accordion-button {
    @media screen and (min-width: 1300px) {
        &.usa-nav-link:hover span {
            border-bottom: none!important;
            padding-bottom: 0!important;
        }
    }
}
//////////////////////////////
// mobile menu
//////////////////////////////
.usa-overlay.is-visible {
    opacity: 0.5;
}
.usa-nav {
    /*animation: slideout-right .3s ease-in-out;*/
    transition: 0.3s ease-in-out;
}
.usa-nav.is-visible {
    z-index: 10001;
    background-color: $uhc-blue;
    padding-right: 0px;
    display: block;
    border-left: none;
    @media (max-width: #{$nav-width - 1}) {
        .usa-nav-primary a, .usa-nav-primary button {
            color: #fff !important;
            font-weight: bold;
            text-decoration: underline;
            padding: 12px 0px 12px 25px;
            border-bottom: 1px solid #0033A0;
        }
        .usa-nav-primary > li {
            border-top: none !important;
        }
    }
}
//////////////////////////////
// mobile menu toggle button
//////////////////////////////
.usa-menu-btn, .usa-nav-close {
	background-color: #edaa1e;
    border-radius: 100%;
    box-shadow: $box-shadow-button;
    color: #FFF !important;
    max-width: 4rem;
}
.usa-nav-close {
	width: 4rem;
    height: 4rem;
	margin-top: 1rem;
    margin-right: 10px;
}
.fixedmobile {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #edaa1e;
    border-radius: 100%;
    z-index: 10000;
}
//////////////////////////////
// footer
//////////////////////////////
footer.page-footer {
    padding-top:0;
    background-color: $uhc-blue;
    margin-top: 20px;
    .footer-copyright{
        height:auto!important;
        line-height: 1.3;
        background-image: url(img/pattern-grid.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        &::before {
            border: 3px solid $uhc-blue;
            width: 100%;
            content: "";
            position: absolute;
            top: 0.8em;
        }
        & > .container > .row {
            padding: 8% 0 0;
            @media (min-width: 600px) {
                padding: 2% 0 0;
            }
        }
        .footer-white{
            background-image: url(img/gold-pattern.svg);
            background-position: top center;
            background-size: 1900px;
            background-repeat: repeat-x;
            background-color: white;
            & > .container {
                padding-top: 30px;
                padding-bottom: 10px;
            }
            @media (max-width: $breakpoint-ipad) {
                background-image:none;
                background-color:#fff;
            }
            nav ul li{
                @media (max-width: $breakpoint-ipad) {
                    background-image:none;
                    background-color:#fff;
                }
                @media (max-width: $breakpoint-small-tablet) {
                    border-right:none;
                    width: 100%;
                }
            }
            div.m7{
                padding-top:6px;
                padding-left:20px;
                @media (max-width: $breakpoint-small-tablet) {
                        padding-left:0;
                }
            }
        }
        h5, p, ul, ol, div {
    	    color: #fff;
    	}
    	h5 {
    	    font-size: 24px;
    	    margin-bottom: 0px;
    	    font-weight: 700;
    	    font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
    	}
    	a {
            color: $uhc-gold;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &:visited {
                color: $uhc-yellow-green;
            }
        }
        .footer-column {
        	line-height: 1.5;
        	@media (min-width: $large-screen) {
        	    padding-right: 20px;
        	}
            ul {
        	    list-style-type: none;
        	    padding-left: 0;
            }
        }
    }
    img.logo {
        width: 100%;
        height: auto;
        max-width: 350px;
    }
    input[type="search"] {
        border: 1px solid $uhc-gold;
    }
    .calling-card {
    	@extend .card;
    	font-size: 38px;
	    color: $uhc-gold !important;
	    background-color: rgba(245, 245, 255, 0.1)!important;
	    margin-top: 4%;
	    display: flex;
	    width: 340px;
	    height: 90px;
	    max-width: 100%;
	    align-items: center;
	    justify-content: center;
	    text-decoration: none;
	    font-weight: bold;
	    border-radius: 6px;
	    &:hover {
	        text-decoration: none!important;
	        background-color: white!important;
	    }
	    &:visited {
	        color: #c0a83f !important;
	    }
	    img {
	    	max-width: 45px;
    		margin-right: 12px;
	    }
	    @media (max-width: $small-screen) {
	        font-size: 28px;
	        img {
	            max-width: 32px;
	        }
	    }
    }
    @media #{$medium-and-down} {
        input[type="text"] {
            max-width: 320px !important;
        }
    }
}
#footer .m4 > .footer-column:nth-of-type(2) p {
    margin-bottom: 0.25em;
}
//////////////////////////////
// sidebar
//////////////////////////////
aside {
    background-color: #fff;
    position: relative;
    padding-bottom: 1%;
    margin-top: 1em;
    @media (min-width: 1200px) {
        margin-top: -230px;
    }
    @media (max-width: $medium-screen) {
        top: 0;
        border: none;
    }
	.sidebar-widget {
		ul {
			list-style-type: none;
		}
	}
	.sidebar-card {
	    @extend .card;
	    max-width: 433px !important;
        position: relative;
        padding-bottom: 230px;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 0 0 6px 6px;
        border: none!important;
        transform: none!important;
        margin-top: 0!important;
        & div:first-child {
            & div:first-child {
                & .card {
                    margin: 0;
                }
            }
        }
        @media (max-width: #{$large-screen - 1}) {
            margin-top: 100px !important;
            margin-right: auto !important;
            margin-left: auto !important;
        }
        img.card-image-full {
            position: absolute;
            top: -105px;
            width: 100%;
            max-width: calc(100% + 4px) !important;
        }
        .textwidget {
           padding: 6px 32px;
           @media (max-width: $small-screen) {
               padding: 6px 16px;
           }
            .lead {
                font-family: 'Helvetica Neue', Helvetica, sans-serif;
                font-weight: bold;
                font-size: 24px;
                line-height: 1.2;
                color: #757575;
            }
            ul {
                list-style: none;
                padding-left: 0;
                font-family: 'Helvetica Neue', Helvetica, sans-serif;
                font-size: 20px;
                color: $bulletcolor;
            }
        }
        .healthgrade-logo {
            width: 298px;
            display: block;
            margin: 0 auto 18px auto;
        }
        .healthgrade-stars {
            background-color: #687385;
            border-radius: 3px;
            padding: 10px 20px;
            text-align: center;
            color: #fff;
            font-size: 32px;
            max-width: 298px;
            margin: 0 auto;
            box-shadow: $box-shadow;
            transition:.25s all ease-in-out;
            &:hover{
                -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                box-shadow: 0 20px 30px rgba(0,0,0,.1);
                -webkit-transform: translate(-2px,-2px);
                transform: translate(-2px,-2px);
            }
        }
        a.hg-link{
            text-decoration:none;
        }
        .sidebar-blue-text {
            text-align: center;
            font-size: 32px;
            font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
            color: #1162a9;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
	    .sidebar-card-title, .sidebar-card-content {
	        padding: 7.5%;
	    }
	    .sidebar-card-title {
	        background: #f0b220;
	        font-size: 18px;
	    }
	    .sidebar-card-content {
	        background: #fff;
	        ul {
	            @include StyledList;
	        }
	    }
	}
	iframe {
        max-width: 100%;
    }
	// widget title
	h5 {
        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
        color: $uhc-blue;
            padding: 16px 0 24px;
            border-bottom: 1px solid #a8b4cc;
            margin-bottom: 30px;
            font-size: 21px;
            line-height: 1.25;
        span {
            font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
            font-weight: bold;
            display:block;
            text-transform: uppercase;
            font-size:27px;
        }
    }
	.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin:0;
            padding:0;
            li{
                list-style:none;
                transition:.25s all ease-in-out;
                background-color:#000;
                &:hover{
                    -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    -webkit-transform: translate(-2px,-2px);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2018/01/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(/wp-content/uploads/2019/02/uhc-er-header3c-1.jpg);
                    background-position:bottom;
                }
                a {
                    padding:8% 5%;
                    text-decoration:none;
                    display:block;
                    border: 1px solid #16375c;
                    font-family: 'Iowan Old Style Bold';
                    font-size:35px;
                    text-transform:uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode:multiply;
                    span{
                        font-weight:300;
                        font-size:13px;
                        display:block;
                        letter-spacing: 3px;
                        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
    }
    .card {
        @media (max-width: #{ $nav-width - 1 }) {
            border: none;
            &:hover {
                box-shadow: none;
            }
        }
    }
    .link-wrap {
        position:relative;
        display: block;
        width: 100%;
        iframe {
            z-index: 2;
        }
        img {
            display: block;
            margin: 0 auto;
            box-shadow: $box-shadow-large;
        }
    }
    .blocker {
        position:absolute;
        height:100%;
        width:100%;
        z-index:1;
    }
// end aside
}
aside.post-sidebar {
    padding-top: 1%;
    padding-left:1%;
    padding-right: 1%;
    border:1px solid #a8b4cc;
    @media (max-width: #{ $nav-width - 1 }) {
        padding: 0;
        border: none;
    }
}
//////////////////////////////
// section
//////////////////////////////
.usa-section {
	padding-top: 50px;
	padding-bottom: 50px;
}
//////////////////////////////
// blogs section
//////////////////////////////
.section-blogs {
    @extend .grey-bg;
}
//////////////////////////////
// blog archive
//////////////////////////////
body.blog{
    .ind-blog {
        @media (min-width: $medium-screen) {
            padding-right:3.5%;
        }
        .card{
            margin: .5rem 2% 2rem 0;
        }
    }
    .sb-links{
        border:none;
    }
}
//////////////////////////////
// single template
//////////////////////////////
.single, .search {
    article {
        max-width: 950px;
        padding-right:3.5%;
        padding-bottom: 50px;
        p strong {
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: bold;}

    	// lists
    	ul {
    		@include StyledList;
    	}
        h2 {
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
            font-weight: bold;
            letter-spacing: 0px;
            text-transform: none;
            font-size: 32px;
            line-height: 38px;
        }
        ol {
            padding-left: 40px;
            counter-reset: list;
            li {
                font-weight: bold;
                list-style: none;
                position: relative;
                &:before {
                    counter-increment: list;
                    content: counter(list) ") ";
                    position: absolute;
                    left: -40px;
                    top: -4px;
                    font-size: 32px;
                    font-family: 'Iowan Old Style Bold';
                }
                h2 {
                    font-family: 'Helvetica Neue', Helvetica, sans-serif !important;
                font-weight: bold;}

                p {
                    font-size: 17px;
                    font-family: 'Helvetica Neue';
                }
            }
        }
        .card {
            padding: 32px;
            margin-top: 64px;
            margin-bottom: 64px;
            font-family: "Iowan Old Style Bold", Helvetica, sans-serif;
            color: $uhc-blue;
            font-size: 24px;
            box-shadow: $box-shadow-large;
            &:hover {
                transform: none !important;
            }
        }
    }
}
//////////////////////////////
// post meta
//////////////////////////////
.post-meta {
	font-size: 0.8em;
	text-transform: uppercase;
}
//////////////////////////////
// pagination
//////////////////////////////
ul.pagination {
	list-style-type: none;
	li {
		display: inline-block;
		margin-right: 10px;
	}
}
body.single{
    .page-heading{
         background-color: rgba(0, 92, 181, 0);
    }
}
//////////////////////////////
// page styles
//////////////////////////////
.page-heading {
    padding: 7% 0 15%;
    position: relative;
    background: rgba(0, 92, 181, 0.22);
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    @media (min-width: 992px) {
        padding: 6% 0 12.5%!important;
    }
    .container {
    	position:relative;
    	z-index:1;
    }
    h2, p {
    	color: #fff;
	    max-width: 650px;
	    text-shadow: 1px 1px 4px #000;
	    strong {
		    font-size: 1.3em;
		    font-weight: 700;
		}
    }
    h1 {
        color: #fff;
	    max-width: 650px;
	    text-shadow: 1px 1px 4px #000;
        strong {
            line-height: 0.85;
            font-size: 1.5em;
            font-weight: normal!important;
        }
    }
    p {
    	color: #fff;
	    margin: 0 0 8px 0 !important;
	    text-shadow: 1px 1px 3px #000;
	    font-size: 22px;
    }
    .p-contain {
        max-width:600px;
        color: #fff;
    }
	a {
		text-shadow: none;
	}
	.usa-button {
	    font-size: 24px;
	}
}
.blog-heading {
    background-position: center;
    position: relative;
    overflow: hidden;
    padding: 10% 0!important;
    .blog-titlebg {
        position: absolute;
        background-color: rgba(104, 117, 99, 0.62);
        height: 1500px;
        width: 1250px;
        top: -106%;
        transform: rotate(33.75deg);
        left: -7%;
        @media (max-width: $large-screen) {
            left: -24%;
        }
    }
    h1 {
        max-width: 620px!important;
    }
}
//////////////////////////////
// diagonal / angle div
//////////////////////////////
.angle-post {
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: 800px) {
        display: none;
    }
    svg {
        height: 200px;
        width: 100%;
    }
    img{
        position:absolute;
        bottom:0;
    }
}
//////////////////////////////
// intro paragraph
//////////////////////////////
.intro-p {
    font-size: 28px;
    line-height: 1.5;
    color: $uhc-blue;
    @media (max-width: $small-screen) {
        font-size: 18px;
    }
}
//////////////////////////////
// main
//////////////////////////////
.blog main {
    padding-bottom: 50px;
}
/*#main-content {
    padding-bottom: 2em;
}*/
//////////////////////////////
// button
//////////////////////////////
a.usa-button, button.usa-button, input[type="submit"].usa-button {
    font-family: 'Helvetica Neue' !important;
    font-size: 17px;
    color: #fff;
    background-color: $uhc-gold;
    transition: all .2s;
    width: auto;
    &:hover {
        background-color: $uhc-yellow-green;
        transition: 0.25s ease-in-out;
    }
    &:active {
        background-color: #e8d27c;
    }
}
.usa-button-primary {
    transition: 0.25s ease-in-out;
    &:hover {
        transition: 0.25s ease-in-out;
    }
}
.usa-button-flat {
    @extend .usa-button;
    border: 2px solid #fff;
    font-family:  'Helvetica Neue',helvetica,arial,sans-serif;
    box-shadow: none;
    background: transparent !important;
    color: #343434;
    cursor: pointer;
    &:hover {
        border: 2px solid #fff;
        background: rgba(0,0,0,.1) !important;
        box-shadow: none;
    }
}
//////////////////////////////
// elements and components
//////////////////////////////
.card {
    /*border-radius: 6px;*/
    border: 1px solid #ececec;
    transition: .25s all ease-in-out;
    position: relative;
    background-color: #fff;
    max-width: 100%;
    .title {
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: bold;
        padding: #{$card-padding / 4} #{$card-padding * 2};
        background: $uhc-gold;
        color: #fff;
        max-width: 100%;
    }
    .content {
		padding: $card-padding #{$card-padding * 2};
		max-width: 100%;
		p:first-child {
		    margin-top: 0;
		}
	}
	.usa-button, .usa-button-flat {
	    display: inline-block;
        color: #fff;
        text-align: center;
        padding: 6px 25px;
	}
    &:hover {
    	box-shadow: $box-shadow-large;
    	transform: translate(-2px, -2px);
    }
}
.card.simple {
    padding: $card-padding;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}
.card.no-hover {
    box-shadow: $box-shadow-large;
	transform: translate(-2px, -2px);
    &:hover {
    	box-shadow: $box-shadow-large !important;
    }
}
.card.callout {
    padding: $card-padding;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    .card-icon {
        max-width: 120px;
        margin-right: 16px;
        float: left;
    }
    @media (max-width: $small-screen) {
        flex-wrap: wrap;
        display: block;
        padding-top: 64px !important;
        padding-bottom: 16px !important;
        .card-icon {
            float: none;
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
//////////////////////////////
// blog tease
//////////////////////////////
a.tease {
	text-decoration: none !important;
	width: 100%;
    border-radius: 6px;
	@media (max-width: $small-screen) {
	    min-height: 0px;
	    height: auto;
	    margin: 0 auto;
	    .content {
    	    font-size: 18px;
    	}
	}
	@media (min-width: 992px) {
	    min-height: 320px;
	}
	img {
        margin: -1px;
        max-width: calc(100% + 2px);
        border-radius: 6px;
    }
    .featured-img {
        border-radius: 6px 6px 0 0;
        width: 100%;
        max-width: calc(100% + 2px);
        height: 160px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .featured-img-link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
        }
    }
	&:hover {
		text-decoration: underline !important;
	}
	.content {
	    font-size: 24px;
	}
}
// doctor cards
/*
#specialists {
    padding-top: 2em;
    & > .col {
        max-width: 400px;
        @media (max-width: 845px) and (max-width: 992px) {
            margin: 0 auto;
        }
    }
}
*/
#topnav {
    position: relative;
    z-index: 1;
    // background: #005eb8;
    // background: -moz-linear-gradient(left,#005eb8 0%,#002855 17%,#002855 95%,#002855 100%);
    // background: -webkit-linear-gradient(left,#005eb8 0%,#002855 17%,#002855 95%,#002855 100%);
    // background: linear-gradient(to right,#005eb8 0%,#002855 17%,#002855 95%,#002855 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005eb8',endColorstr='#002855',GradientType=1 );
    background-image: url("/wp-content/uploads/2020/11/bkg_Coats-for-Kids-Proof-UHC-LPs-Alerts.jpg");
    background-size: cover;
    background-position: center center;
    padding-bottom: 4em;
    overflow: hidden;
    div.kid-coats {
        display: none;
    }
    &:before {
        content: "";
        position: absolute;
        margin-left: 0;
        right: 0;
        left: 0;
        margin: auto;
        top: 1em;
        bottom: -1em;
        width: 8em;
        background-image: url('/wp-content/uploads/2020/11/coats-for-kids-kids.png');
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
    .top-container {
        min-height: 75px;
        // width: 95%!important;
        text-align: center;
        color: #fff;
        font-size: 18px;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        @media (max-width: 600px) {
            // max-width: 85%!important;
            // width: 85%!important;
            // display: block;
            // padding: 18px 0;
            .top-copy {
                justify-content: center;
                font-size: 18px;
                padding-bottom: 10px;
                margin: auto;
                // span span {
                //     display: none!important;
                // }
            }
        }
        & > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 10px;
            width: 100%;
        }
        .top-copy {
            display: inline-flex;
            align-items: center;
            span {
                line-height: 1.25;
                &.coats {
                    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
                    span.gold {
                        color: $uhc-gold;
                        font-size: 1.5em;
                    }
                }
            }
            img {
                margin-right: 1rem;
                max-width: 35px;
            }
        }
        a.btn {
            font-size: .8em;
            margin: 0 1rem;
            padding: .65em 1.3em;
            @extend .usa-button;
            color: white;
            background-color: $uhc-gold!important;
            transition: 0.25s ease-in-out;
            width: 150px;
            min-width: 9.25em;
            &:hover {
                background-color: lighten($uhc-gold, 10%)!important;
                transition: 0.25s ease-in-out;
            }
        }
    }
    @media (min-width: 600px) {
        padding-bottom: 0;
        div.kid-coats {
            display: block;
            position: relative;
            width: 100%;
            max-width: 10em;
            height: 100%;
            & > div {
                max-width: 14em;
                height: 5em;
                width: 100%;
                bottom: -2em;
                position: absolute;
                background-image: url('/wp-content/uploads/2020/11/coats-for-kids-kids.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
            }
        }
        &:before {
            display: none;
        }
        .top-container {
            flex-direction: row;
            & > div {
                flex: 1;
                flex-direction: row;
                justify-content: center;
                &:nth-of-type(2) {
                    border-left: 1px solid lighten($uhc-blue, 12%);
                }
            }
            .top-copy {
                // margin-left: auto;
                margin-right: 15px;
                text-align: left;
            }
            a.btn {
                // margin: 0 auto 0 0;
                margin: 0;
            }
        }
    }
}
//////////////////////////////
// Welcome section
//////////////////////////////
#welcome div:nth-of-type(2) {
    padding-left: 1em;
}
.welcome-card, .treatment-card, .testimonial-card {
    flex: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 6px;
    overflow: hidden;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block!important;
    }
    & > p {
        display: none;
    }
    & > a {
        width: 100%;
        text-decoration: none;
    }
    &:hover {
        .welcome-wrap, .treatment-wrap, .testimonial-wrap {
             &:after {
                background: rgba(0, 40, 87, 0);
                transition: 0.25s ease-in-out;
            }
            .treatment-still, .testimonial-still {
                filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                filter: grayscale(0%);
                -webkit-filter: grayscale(0%);
                filter: gray;
                transition: 0.25s ease-in-out;
            }
         }
    }
    .welcome-wrap, .treatment-wrap, .testimonial-wrap {
        width: 100%;
        overflow: hidden;
        position: relative;
        .treatment-still, .testimonial-still {
            filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            filter: gray;
            transition: 0.25s ease-in-out;
            display: block;
            width: 100%;
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 40, 87, 0.30);
            transition: 0.25s ease-in-out;
        }
        .welcome-still {
            transition: 0.25s ease-in-out;
            display: block;
            min-width: 100%;
            position: relative;
        }
    }
    .welcome-wrap::after {
        background: none!important;
    }
    .bottom-welcome, .bottom-treatment, .bottom-testimonial {
        z-index: 50!important;
        position: relative!important;
        background-color: white!important;
        max-width: 100%;
	    padding: 32px;
	    &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 60px;
            top: -1.75em;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-image: url(/wp-content/uploads/2017/02/play-btn-surgeryico-.png);
            background-size: contain;
            background-repeat: no-repeat;
        }
	    p:first-child {
    	    margin: 10px 0px 0px 0px;
    	    width: 100%;
    	    -ms-flex: 0 1 auto
    	}
    }
    .bottom-welcome::before {
        background-image: none!important;
    }
    h5 {
        display: inline-block;
        height: fit-content;
        width: 65%;
        line-height: 1.1;
        margin: auto;
        position: absolute;
        font-size: 21px;
        font-family: 'Helvetica Neue';
        font-weight: 600;
        top: 0;
        color: $uhc-blue;
        bottom: 0;
        @media (min-width: 600px) {
            font-size: 23px;
        }
        span {
            font-size: 0.6em;
            font-weight: 600;
            color: $uhc-blue;
        }
	}
}
//////////////////////////////
// Treatments Section
//////////////////////////////
.treatments-image {
    width: 100%;
    height: auto;
    display: block;
}
.treatments-section {
    background-color: #486086;
    .treatments-content {
        position: relative;
        h2 {
            color: white;
            font-weight: 700;
            margin: 0 auto;
            text-align: center;
            line-height: 1;
            margin-top: 1em;
        }
        .treatments-desc {
            color: white;
            margin: 0 auto;
            text-align: center;
            line-height: 1.75;
            font-size: 1.3em;
            letter-spacing: 0.008em;
            margin: 1em 0;
        }
        .treatments-row {
            .m6 {
                text-align: center;
                margin-top: 2em;
                @media (min-width: 992px) {
                    min-height: 25em;
                }
                a {
                    &:hover {
                        img {
                            transform: scale(0.85);
                            transition: 0.25s ease-in-out;
                        }
                    }
                    &:focus {
                       img {
                            filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.1));
                       }
                    }
                    img {
                        width: 75px;
                        height: 75px;
                        filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.3));
                        transition: 0.25s ease-in-out;
                        & + p {
                            margin: 0!important;
                        }
                        @media (min-width: 1200px) {
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
                h4 {
                    color: white;
                    font-size: 1.5em;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: 'Helvetica Neue Condensed', Helvetica, arial, sans-serif;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        border-bottom: 1px solid white;
                        color: white;
                        width: 80%;
                        margin: 0 auto;
                        margin-bottom: 0.5em;
                        padding-bottom: 0.25em;
                    }
                }
            }
            .m6:last-of-type {
                padding-bottom: 4em;
            }
        }
    }
}
//////////////////////////////
// Treatments Page Section
//////////////////////////////
.treatments-page-section {
    .treatments-content {
        position: relative;
        .treatments-desc {
            color: $uhc-blue;
            margin: 0 auto;
            text-align: center;
            line-height: 1.75;
            font-size: 1.3em;
            letter-spacing: 0.008em;
            margin: 1em 0;
        }
        .treatments-row {
            margin-bottom: 4em;
            .m6 {
                text-align: center;
                border-radius: 6px;
                overflow: hidden;
                box-shadow: $box-shadow-large;
                max-width: 28em;
                margin: 0 auto!important;
                margin-top: 1em!important;
                @media (min-width: 992px) {
                    padding: 0 1em 0 0;
                    box-shadow: none;
                    padding-bottom: 2em;
                    overflow: visible;
                }
                .treatment-page-card {
                    box-shadow: $box-shadow-large;
                    @media (min-width: 992px) {
                        border-radius: 6px;
                        overflow: hidden;
                    }
                    @media (min-width: 992px) and (max-width: 1200px) {
                        min-height: 26em;
                    }
                    .treatment-header {
                        background-size: cover;
                        background-position: left top;
                        a {
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                    transition: 0.25s ease-in-out;
                                    filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white);
                                }
                            }
                            img {
                                width: 75px;
                                height: 75px;
                                filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white);
                                transition: 0.25s ease-in-out;
                                margin-bottom: -3em;
                                margin-top: 4em;
                                & + p {
                                    margin: 0!important;
                                }
                                @media (min-width: 1200px) {
                                    width: 120px;
                                    height: 120px;
                                }
                            }
                        }
                    }
                    .treatment-card-content {
                        padding: 3em 1em 1em 1em;
                        @media (min-width: 992px) {
                            min-height: 22em;
                            position: relative;
                        }
                        h4 {
                            color: $uhc-blue;
                            font-size: 1.4em;
                            font-weight: 700;
                            line-height: 1.2!important;
                        }
                        ul {
                            list-style: none;
                            padding: 0;
                            & > li {
                                border-bottom: 1px solid $uhc-blue;
                                color: $uhc-blue;
                                width: 80%;
                                margin: 0 auto;
                                margin-bottom: 0.5em;
                                padding-bottom: 0.25em;
                                &:last-of-type {
                                    border-bottom: none;
                                }
                            }
                        }
                        & > p {
                            margin: 0;
                            @media (min-width: 992px) {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                padding: 1em;
                                padding-bottom: 0.65em;
                            }
                            a {
                                @extend .usa-button;
                                width: 100%;
                                margin-bottom: 0;
                                background-color: $uhc-gold!important;
                                &:hover {
                                    background-color: lighten($uhc-gold, 10%)!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
ul.jump-links, ul.footer-page-links {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 0.5px solid $color-gray-lightest;
    border-bottom: none;
    border-radius: 6px;
    margin: 2em 0;
    transition: 0.25s ease-in-out;
    @media (min-width: 992px) {
        margin-right: 1.5em;
    }
    li {
        margin: 0;
        border-bottom: 0.5px solid $color-gray-lightest;
        text-align: center;
        &:last-child {
            border-radius: 6px;
        }
        a {
            text-decoration: none;
            display: block;
            padding: 0.5em 1em;
            transition: 0.25s ease-in-out;
            font-weight: 500;
            color: $uhc-blue!important;
            &:hover {
                background-color: #fbfbfb;
                transition: 0.15s ease-in-out;
            }
        }
    }
}
@media (min-width: 992px) {
    ul.footer-page-links {
        display: none;
    }
}
.fixed-jump-links {
    @media (min-width: 992px) {
        position: fixed;
        top: 2em;
        left: 0em;
        margin: 1em!important;
        width: 13.5em;
    }
    @media (min-width: 1600px) {
        left: 8em;
        width: 16em;
    }
}
.treatments-page-container {
    .content-area {
        & > h2 {
            font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
            font-size: 3em!important;
            color: $uhc-blue;
            font-weight: 400!important;
            text-transform: capitalize!important;
            letter-spacing: 0px;
            @media (max-width: $breakpoint-phone) {
                font-size: 2em!important;
                line-height: 1!important;
            }
        }
        .intro-p {
            font-size: 24px!important;
            @media (min-width: 992px) {
                padding-right: 2em;
                font-size: 28px!important;
                margin: 0!important;
            }
        }
        .video > p {
            display: none;
        }
        .treatment-page-header {
            p {
                display: none;
            }
            @media (min-width: 992px) {
                margin-bottom: 2em;
            }
        }
        .treatment-card {
            margin-bottom: 2em!important;
        }
        .bottom-treatment {
            line-height: 1.3;
            padding-top: 3em;
        }
        #surgical-approaches {
            margin-top: 2em;
            padding-top: 2em;
            display: inline-block;
            .treatment-page-header {
                border-bottom: 4px solid $uhc-violet;
                padding-bottom: 0.5em;
                img {
                    padding: 0.5em;
                    background-color: $uhc-violet;
                    border-radius: 100%;
                    width: 70px;
                    height: 70px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
                    @media (min-width: 992px) {
                        margin-bottom: -0.8em;
                        margin-right: 1em;
                    }
                }
                h2 {
                    color: $uhc-violet;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            h3 {
                color: $uhc-violet;
            }
        }
        #chemotherapy-biotherapy {
            margin-top: 2em;
            padding-top: 2em;
            display: inline-block;
            .treatment-page-header {
                border-bottom: 4px solid $uhc-seafoam;
                padding-bottom: 0.5em;
                img {
                    padding: 0.5em;
                    background-color: $uhc-seafoam;
                    border-radius: 100%;
                    width: 70px;
                    height: 70px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
                    @media (min-width: 992px) {
                        margin-bottom: -0.8em;
                        margin-right: 1em;
                    }
                }
                h2 {
                    color: $uhc-seafoam;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            h3 {
                color: $uhc-seafoam;
            }
        }
        #radiation {
            margin-top: 2em;
            padding-top: 2em;
            display: inline-block;
            .treatment-page-header {
                border-bottom: 4px solid #f1a841;
                padding-bottom: 0.5em;
                img {
                    padding: 0.5em;
                    background-color: #f1a841;
                    border-radius: 100%;
                    width: 70px;
                    height: 70px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
                    @media (min-width: 992px) {
                        margin-bottom: -0.8em;
                        margin-right: 1em;
                    }
                }
                h2 {
                    color: #f1a841;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            h3 {
                color: #f1a841;
            }
        }
        #clinical-trials {
            margin-top: 2em;
            padding-top: 2em;
            display: inline-block;
            .treatment-page-header {
                border-bottom: 4px solid $uhc-olive;
                padding-bottom: 0.5em;
                img {
                    padding: 0.5em;
                    background-color: $uhc-olive;
                    border-radius: 100%;
                    width: 70px;
                    height: 70px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
                    @media (min-width: 992px) {
                        margin-bottom: -0.8em;
                        margin-right: 1em;
                    }
                }
                h2 {
                    color: $uhc-olive;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            h3 {
                color: $uhc-olive;
            }
        }
        #communicating-with-your-medical-team {
            margin-top: 2em;
            padding-top: 2em;
            display: inline-block;
            .treatment-page-header {
                border-bottom: 4px solid #7bc0dd;
                padding-bottom: 0.5em;
                img {
                    padding: 0.5em;
                    background-color: #7bc0dd;
                    border-radius: 100%;
                    width: 70px;
                    height: 70px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
                    @media (min-width: 992px) {
                        margin-bottom: -0.8em;
                        margin-right: 1em;
                    }
                }
                h2 {
                    color: #7bc0dd;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            h3 {
                color: #7bc0dd;
            }
        }
    }
}
a.link1 .treatment-wrap:after {
    background-color: rgba(124, 140, 166, 0.5);
}
a.link2 .treatment-wrap:after {
    background-color: rgba(134, 168, 170, 0.3);
}
a.link3 .treatment-wrap:after {
    background-color: rgba(241, 168, 65, 0.3);
}
a.link4 .treatment-wrap:after {
    background-color: rgba(148, 164, 151, 0.4);
}
.bottom-treatment.surgery2 {
    &:before {
        background-image: url(/wp-content/uploads/2017/02/play-btn-surgeryico-.png)!important;
        width: 65px;
        height: 65px;
    }
}
.bottom-treatment.chemo2 {
	    &:before {
            background-image: url(/wp-content/uploads/2017/03/play-btn-chemoico-.png)!important;
            width: 65px;
            height: 65px;
        }
    }
.bottom-treatment.radiation2 {
    &:before {
        background-image: url(/wp-content/uploads/2017/03/play-btn-radiationico-.png)!important;
        width: 65px;
        height: 65px;
    }
}
.bottom-treatment.trials2 {
    &:before {
        background-image: url(/wp-content/uploads/2017/03/play-btn-trialsico-.png)!important;
        width: 65px;
        height: 65px;
    }
}
.page-template-page-specific-cancers.page-child {
    #main-content {
        background-color: #333;
    }
    .info-section {
        color: #fbfbfb;
        .container {
            @media (min-width: 992px) {
                padding-right: 3vw!important;
            }
        }
        blockquote {
            margin: 4em 0;
            p {
                font-size: 20px;
            }
            h3 {
                font-size: 36px!important;
                line-height: 40px!important;
                color: #dc5551!important;
            }
            h4 {
                color: white!important;
            }
            p.cite {
                font-size: 13px!important;
                line-height: 1.2;
                color: #afafaf;
                cite a {
                    color: $uhc-gold!important;
                    font-style: italic;
                    text-decoration: none;
                    transition: 0.25s ease-in-out;
                    &:hover {
                        color: lighten($uhc-gold, 10%)!important;
                        transition: 0.15s ease-in-out;
                    }
                }
            }
            .screenings-cta {
                a {
                    @extend .usa-button;
                    background-color: $uhc-gold!important;
                    transition: 0.25s ease-in-out;
                    &:hover {
                        background-color: lighten($uhc-gold, 10%)!important;
                        transition: 0.15s ease-in-out;
                    }
                }
            }
        }
        .blue-callout {
            color: #8397c8;
            font-weight: 800;
            font-size: 36px!important;
            line-height: 1.1;
            padding-bottom: 2%;
            margin-bottom: 2%;
            border-bottom: 4px solid #8397c8;
            @media (max-width: 600px) {
                font-size: 24px!important;
            }
        }
        .card {
            border: none!important;
        }
        ul.browser-default {
            list-style: none;
            li {
                font-size: 20px;
                max-width: 74%;
                padding: 1% 0;
                position: relative;
                &:before {
                    content: '';
                    width: 20px;
                    display: inline-block;
                    background-image: url(/wp-content/uploads/2017/07/bullet1.png);
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 10px;
                    left: -33px;
                    @media (min-width: 992px) {
                        top: 14px;
                    }
                }
            }
        }
    }
    aside {
        background-color: transparent!important;
    }
}
//////////////////////////////
// Screenings Section
//////////////////////////////
.screenings-section {
    background-image: url(/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_06.jpg);
    background-size: cover;
    .s12 {
        padding: 2em 0 1em 0;
        h3 {
            color: white;
            font-size: 2em;
        }
        p {
            color: $color-secondary-lightest;
        }
        &:first-of-type {
            text-align: center;
            padding: 4em 0 1em 0;
        }
    }
    .usa-button {
        background-color: $uhc-gold!important;
        margin-right: 0;
    }
}
.page-template-page-screenings .page-heading .usa-button {
    background-color: $uhc-gold!important;
    &:hover {
        background-color: lighten($uhc-gold, 10%)!important;
    }
}
//////////////////////////////
// Support Section
//////////////////////////////
.support-section {
    background-image: url(/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_07.jpg);
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    img.support-icon {
        width: 75px;
        height: 75px;
        margin: 0 auto;
        display: block;
        margin-top: 2em;
        filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.3));
        & + p {
            margin: 0!important;
        }
    }
    .m4 .res {
        max-width: 325px;
        margin: 0 auto;
        display: block;
        @media (min-width: 992px) {
            float: right;
            margin-right: 1em;
        }
    }
    .m8 p {
        max-width: 680px;
        margin: 0 auto;
        margin-top: 1em;
        @media (min-width: 992px) {
            margin-left: 1em;
            margin-top: 0;
            margin-right: 0;
            padding: 2em;
        }
    }
    .s12.logos img {
        margin: 0 auto;
        width: 90%;
        height: auto;
    }
    h3 {
        margin: 1em 0;
        text-align: center;
    }
}
//////////////////////////////
// Provisions Section
//////////////////////////////
.provisions-section {
    background-image: url(/wp-content/uploads/2017/03/blue-top.jpg);
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #82bfdf;
    #tab-1, #tab-2, #tab-3, #tab-4 {
        @media (max-width: 768px) {
            display: inline-block!important;
        }
    }
    div.active {
        .m6:first-of-type {
        @media (min-width: 992px) {
            padding-right: 0.5em;
            min-height: 26em;
        }
        }
        .m6:nth-of-type(2) {
        @media (min-width: 992px) {
            padding-left: 0.5em;
            min-height: 26em;
        }
        }
    }
    .provisions-intro {
        text-align: center;
        h2 {
            color: white;
            padding: 2em 0 1em 0;
            margin: 0;
            line-height: 1;
        }
        p {
            color: $uhc-blue;
            margin: 0;
            margin-bottom: 1.5em;
        }
    }
    ul.tabs {
        list-style: none;
        padding: 0;
        background-color: transparent;
        padding-bottom: 1em;
        height: auto;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        margin: 0 auto;
        white-space: nowrap;
        @media (max-width: 768px) {
            display: none!important;
        }
        .indicator {
            background-color: white!important;
            bottom: 0.5em;
            position: absolute;
            height: 2px;
            will-change: left, right;
        }
        li.tab {
            height: initial;
            color: white;
            font-weight: 700;
            padding: 0 0.65em;
            a {
                padding: 1em 0;
                border-radius: 6px;
                border: 1px solid white;
                display: block;
                width: 100%;
                height: 100%;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-decoration: none;
                -webkit-transition: color .28s ease-in-out, background-color .25s ease-in-out;
                transition: color .28s ease-in-out, background-color .25s ease-in-out, box-shadow .15s ease-in-out;
                box-shadow: $box-shadow-large;
                &.active {
                    box-shadow: none;
                    transition: color .28s ease-in-out, background-color .28s ease-in-out, box-shadow .15s ease-in-out;
                }
                &:hover {
                    transition: background-color .15s ease-in-out;
                }
            }
            img {
                width: 60px;
                margin: 0 auto;
                display: block;
            }
            div {
                color: white;
                font-weight: 700;
                white-space: initial;
                text-align: center;
            }
            &:nth-child(1) a {
                background-color: lighten($uhc-violet, 10%);
                &.active {
                    background-color: $uhc-violet;
                }
                &:hover {
                    background-color: $uhc-violet;
                }
            }
            &:nth-child(2) a {
                background-color: lighten($uhc-seafoam, 10%);
                &.active {
                    background-color: $uhc-seafoam;
                }
                &:hover {
                    background-color: $uhc-seafoam;
                }
            }
            &:nth-child(3) a {
                background-color: lighten(#f1a841, 10%);
                &.active {
                    background-color: #f1a841;
                }
                &:hover {
                    background-color: #f1a841;
                }
            }
            &:nth-child(4) a {
                background-color: lighten($uhc-olive, 10%);
                &.active {
                    background-color: $uhc-olive;
                }
                &:hover {
                    background-color: $uhc-olive;
                }
            }
        }
    }
    .card {
        border-radius: 6px;
        overflow: hidden;
    }
    .treatment-icon-wrapper {
        padding: 0.5em 0;
    }
    .learn-top {
        h4 {
            color: white;
            text-align: center;
            padding-bottom: 0.5em;
            margin-bottom: 0;
        }
    }
    .learn-bottom {
        p {
            padding: 1em 1.5em;
            margin: 0;
        }
        a {
            @extend .usa-button;
            padding: 1em 0.5em;
            display: block;
            margin: 0 auto;
        }
    }
    .surgery {
        background-color: $uhc-violet;
        .treatment-icon {
            width: 100px;
            display: block;
            margin: 0 auto;
            background-color: lighten($uhc-violet, 10%);
            padding: 1em;
            border-radius: 100%;
        }
    }
    .chemo {
        background-color: $uhc-seafoam;
        .treatment-icon {
            width: 100px;
            display: block;
            margin: 0 auto;
            background-color: lighten($uhc-seafoam, 10%);
            padding: 1em;
            border-radius: 100%;
        }
    }
    .radiation {
        background-color: #f1a841;
        .treatment-icon {
            width: 100px;
            display: block;
            margin: 0 auto;
            background-color: lighten(#f1a841, 10%);
            padding: 1em;
            border-radius: 100%;
        }
    }
    .trials {
        background-color: $uhc-olive;
        .treatment-icon {
            width: 100px;
            display: block;
            margin: 0 auto;
            background-color: lighten($uhc-olive, 10%);
            padding: 1em;
            border-radius: 100%;
        }
    }
    .treatment-icon {
        @media (max-width: 768px) {
            width: 75px!important;
            padding: 0.45em!important;
        }
    }
}
//////////////////////////////
// SideNav
//////////////////////////////
.sidenav {
    background-color: #EDF7FD;
    height: 100%; /* 100% Full-height */
    width: 300px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 10001; /* Stay on top */
    top: 0; /* Stay at the top */
    right: -300px;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0.25em; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    a {
        text-decoration: none;
        font-size: 25px;
        display: block;
        transition: 0.3s ease-in-out;
        color: $uhc-blue;
        &:hover {
            color: lighten($uhc-blue, 10%);
            transition: 0.3s ease-in-out;
        }
    }
    ul {
        list-style: none;
        padding-left: 0.5em;
        li {
            strong {
                color: darken($uhc-blue, 20%);
                opacity: 0.6;
            }
            a {
                font-weight: 700;
            }
        }
    }
    #sidenav-container {
        padding: 0 1em;
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
        transition-delay: 0.4s;
        opacity: 0;
    }
}
.closebtn-container {
    width: 100%;
    display: block;
    text-align: right;
    .closebtn {
        display: inline-block;
        padding: 0.25em;
        border-radius: 100em;
        background-color: $uhc-blue;
        color: white;
        width: 45px;
        height: 45px;
        transition: .25s ease-in-out;
        text-align: center;
        &:hover {
            color: white;
            opacity: 0.8;
            transition: .25s ease-in-out;
        }
    }
}
#page-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background-color: #223865;
    z-index: 10000;
    display: none;
}
.slide-in {
    right: 0;
}
.fade-in {
    opacity: 1!important;
}
.overlay-fade {
    opacity: 0.7!important;
    position: fixed!important;
    display: block!important;
}
/* Position and style the close button (top right corner)
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}*/
//////////////////////////////
// Journey Section
//////////////////////////////
.journey-section {
    padding-bottom: 2em;
    margin-top: -3em;
    @media(min-width: 992px) {
        margin-top: -6em;
    }
    h2 {
        color: $uhc-blue;
        text-align: center;
    }
    .journey-background {
        background-color: #205986;
        @media (min-width: 768px) {
            background-image: url(/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_10.jpg);
            background-size: cover;
            background-position: top right;
            min-height: 16em;
            .m5 {
                float: right;
                padding: 3em;
            }
        }
        @media (min-width: 768px) and (max-width: 1200px) {
            .m5 {
                width: 41.66666666666%;
                padding-top: 4em;
                padding-bottom: 4em;
            }
        }
        @media (min-width: 1200px) {
            padding-top: 7em;
            padding-bottom: 7em;
        }
    }
    img {
        width: 75px;
        height: 75px;
        margin: 0 auto;
        display: block;
        margin-top: 2em;
        filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.3));
        & + p {
            margin: 0!important;
        }
    }
    p {
        color: white;
    }
}
//////////////////////////////
// Testimonials Section
//////////////////////////////
.testimonials-section {
    padding: 2em 0em;
    background-image: url('/wp-content/uploads/2019/02/build-video-block-background2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 6em;
    .l6 {
        @media (min-width: 992px) {
            padding-left: 1em;
        }
    }
    h2 {
        text-align: center;
        color: $uhc-blue;
        line-height: 1;
    }
    .quote-card {
        border-radius: 6px;
        overflow: hidden;
        @media (min-width: 768px) {
            padding-right: 4em;
        }
        p {
            padding: 1em;
            margin: 0;
            font-size: 1.5em;
            color: $uhc-blue;
        }
        a {
            @extend .usa-button;
            width: 100%;
            margin: 0;
        }
    }
    .row {
        @media (min-width: 768px) {
            display: flex;
            .quote-card {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                display: table;
            }
        }
    }
}
//////////////////////////////
// Trust Section
//////////////////////////////
.trust-section {
    background-color: #87C2DE;
    background-image: none;
    padding: 7% 0 9%;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 700px) {
        background-image: url(/wp-content/uploads/2019/02/ortho-doctor-block-background-1-1.png);
        background-color: transparent;
    }
    .trust-card {
        border-radius: 6px;
        overflow: hidden;
        max-width: 600px;
        margin: 0 auto;
        display: block;
        margin-top: 1em;
        & > p:nth-of-type(2) {
            margin: 0 auto;
            padding: 1em;
        }
    }
    .trust-content {
        & > div {
            max-width: 600px;
            margin: 0 auto;
            display: block;
            width: auto!important;
            @media (min-width: 992px) {
                padding-left: 1em;
            }
            & > p:nth-of-type(2) {
                font-size: 1.2em;
            }
        }
        h2 {
            color: $uhc-blue;
            margin-top: 0.5em;
            line-height: 1;
        }
        img {
            width: 75px;
            height: 75px;
            display: block;
            margin-top: 2em;
            filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.3));
            & + p {
                margin: 0!important;
            }
        }
        a {
            @extend .usa-button;
        }
    }
}
//////////////////////////////
// Communication Section
//////////////////////////////
.communication-section {
    background-color: #d7e4dd;
    padding: 2em 0em;
    .m3 {
        img {
            max-width: 300px;
            margin: 0 auto;
            display: block;
        }
    }
    .m9 {
        & > p {
            font-size: 1.5em;
            color: $uhc-blue;
            @media (min-width: 768px) {
                padding: 0 1em;
                margin: 0;
                &:first-of-type {
                    padding: 1em;
                    margin-top: initial;
                }
            }
        }
        a {
            @extend .usa-button;
        }
    }
    .row {
        @media (min-width: 768px) {
            display: flex;
            .m9 {
                & > p {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    display: table;
                }
            }
        }
    }
}
//////////////////////////////
// sections and background
//////////////////////////////
.grey-bg {
    background-color: #e8eaec;
    padding-bottom: 5%;
}
//////////////////////////////
/// map style
//////////////////////////////
.map-container {
    .infowindow {
        position: absolute;
        display: none;
        top: 50%;
        width: 474px;
        left: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 2;
        margin-left: -601.5px;
        margin-top: -200px;
        padding: 1.5em;
        font-weight: 300;
        font-size: 24px;
        border-radius: 6px;
        .infowindow-margin {
            p:nth-of-type(2) {
                margin: 0!important;
                & > a {
                    margin: 0;
                }
            }
        }
        img {
            height: 46px;
            @media (min-width: 500px) {
                height: 56px;
            }
        }
        .usa-button {
            width: 100%;
            text-align: center;
            padding: 1em 0;
            background-color: $uhc-gold;
            text-decoration: none;
            transition: $global-transition;
            color: $uhc-blue;
            border-radius: 6px;
            margin: 0 auto;
            margin-bottom: 0;
            font-weight: 700;
            display: block;
            &:hover {
                opacity: 0.9;
                transition: $global-transition;
                background-color: $uhc-gold;
                color: $uhc-blue;
            }
        }
        @media (max-width: 1270px) {
            top: 18%;
            left: 10%;
            margin-left: 0;
            margin-top: 0;
            width: 474px;
        }
        @media (max-width: $small-screen) {
            display: none!important;
        }
        @media (min-width: 1320px) {
            left: 45%;
        }
    }
    #map3 {
        height: 400px;
        position: relative;
        z-index: 1;
        background-image: url('/wp-content/uploads/2021/04/uhc-map-blurred.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        #showMap {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $helvetica-condensed!important;
            font-size: 1.2em;
            padding: 0.75em 2em;
            max-width: 250px;
        }
    }
    &.activated {
        #map3 {
            height: 600px;
        }
        .infowindow {
            display: block;
        }
    }
}
// #map {
//     height: 600px;
//     position: relative;
//     z-index: 1;
//     @media (max-width: $small-screen) {
//     	display: none;
//     }
// }
// .infowindow {
//     position: absolute;
//     top: 50%;
//     width: 474px;
//     left: 50%;
//     background-color: rgba(255, 255, 255, 0.8);
//     z-index: 2;
//     margin-left: -601.5px;
//     margin-top: -200px;
//     padding: 1.25em;
//     color: #6d665c;
//     font-weight: 300;
//     font-size: 24px;
//     @media (max-width: 1270px) {
//     	display: none;
//     }
//     img {
//         height: 45px;
//         @media (min-width: 500px) {
//             height: 55px;
//         }
//     }
//     p:last-of-type {
//         margin-bottom: 0;
//     }
//     .usa-button {
//         width: 100%;
//         text-align: center;
//         padding: 1em 0;
//         background-color: $uhc-gold;
//         text-decoration: none;
//         -webkit-transition: .2s all;
//         transition: .2s all;
//         color: $uhc-blue;
//         border-radius: 6px;
//         margin: 0 auto 1em;
//         font-weight: 700;
//         margin-bottom: 0;
//         display: block;
//         &:hover {
//             transition: .2s all;
//             background-color: $uhc-gold;
//             color: $uhc-blue;
//         }
//     }
//     p strong {
//         display: inline-block;
//         line-height: 1.25;
//     }
// }
//////////////////////////////
// home page
//////////////////////////////
.home {
    .page-heading {
        p:first-of-type {
            font-size: 0.9em;
            line-height: 1.5;
            margin-bottom: 2em!important;
        }
    }
	@media (max-width: 600px) {
        .page-heading {
        background-image: -webkit-linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(/wp-content/uploads/2019/01/uhc-specialty-main-lp-header3.jpg)!important;
        background-image: -moz-linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(/wp-content/uploads/2019/01/uhc-specialty-main-lp-header3.jpg)!important;
        background-image: -o-linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(/wp-content/uploads/2019/01/uhc-specialty-main-lp-header3.jpg)!important;
        background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(/wp-content/uploads/2019/01/uhc-specialty-main-lp-header3.jpg)!important;
        background-position: top left!important;
        }
	}
}
//////////////////////////////
// single doctors
//////////////////////////////
.single-doctors {
    .post-type-doctors {
        margin-top: 32px;
        padding-bottom: 50px;
        .row .m7 {
            h1 {
                margin-bottom: 0;
            }
            & > p:first-of-type {
                margin: 0;
                font-weight: 600;
                color: $uhc-blue;
                font-size: 1.2em;
            }
        }
        .row .col:first-of-type div div {
            @media (min-width: 992px) {
                .doc-svg {
                    bottom: 8px!important;
                }
            }
        }
    }
}
//////////////////////////////
// utility classes
//////////////////////////////
.padding-5-0 {
    padding-top: 5%;
    padding-bottom: 5%;
}
.no-mobile {
    @media (max-width: #{$medium-screen - 1}) {
        display: none;
    }
}
.yes-mobile {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: block !important;
    }
}
.yes-mobile-inline {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: inline !important;
    }
}
.yes-mobile-flex {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: flex !important;
    }
}
.mobile-block-center {
    @media (max-width: #{$medium-screen - 1}) {
        display: block;
        margin: 0 auto;
    }
}
.mobile-nav-link {
    display: none !important;
    @media (max-width: #{$nav-width - 1 }) {
        display: block !important;
    }
}
.negativemargtop {
    @media (min-width: $large-screen) {
        margin-top: -243px;
    }
}
.float-right-no-mobile {
    float: right;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
.float-left-no-mobile {
    float: left;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
//////////////////////////////
// margin classes
//////////////////////////////
.margin-left-no-mobile {
    margin-left: 16px;
    @media (max-width: $medium-screen) {
        margin-left: 0;
    }
}
.margin-right-no-mobile {
    margin-right: 16px;
    @media (max-width: $medium-screen) {
        margin-right: 0;
    }
}
.flex-end-self {
    align-self: flex-end;
}
.et_monarch .et_social_networks ul.et_social_icons_container {
    display: flex !important;
    align-items: center;
    @media (max-width: $small-screen) {
        justify-content: space-between;
    }
    li {
        width: auto !important;
    }
}
//////////////////////////////
// top bar
//////////////////////////////
#topbar {
    background: linear-gradient(to right, #005eb8 0%, #002855 17%, #002855 95%, #002855 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005eb8', endColorstr='#002855',GradientType=1 );
    position: relative;
    z-index: 1;
    .top-container {
        width: 90% !important;
        min-height: 75px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 56px;
        color: #fff;
        font-size: 18px;
        line-height: 1;
        img {
            margin-right: 1rem;
        }
        .usa-button, .usa-button-flat {
            font-size: 16px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}
.uhc-bar {
    background-color: #17375c;
    text-align: right;
    background-image: url(img/pattern-grid.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    @media (max-width: #{$nav-width -  1}) {
        display: none;
    }
    .usa-nav-container {
        height: auto;
        padding: 5px 55px;
    }
    a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
.notch {
    width: 48px;
    height: 48px;
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    top: -24px;
    left: 0px;
    @media (max-width: $small-screen) {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }
}
// search field
.usa-search.usa-search-small {
    margin-bottom: 1rem;
    input[type="search"] {
        width: calc(100% - 45px);
        max-width: 100%;
        height: 35px;
    }
    [type="submit"] {
        background-color: $uhc-gold;
        height: 35px;
    }
}
.usa-search {
    max-width: 18em;
}
// include custom site styles
@import "custom";
#bitnami-banner .bitnami-corner-image-div .bitnami-corner-image{
    display:none!important;
}
p.no-p{
    display:inline-block;
}
//////////////////////////////
// new blog styles, and sidebar styles, to copy over past this point
//////////////////////////////
.blogarchive {
    padding:11.5% 0 15%;
    position: relative;
    z-index: 9;
    h2 {
        color: #fff;
        max-width: 615px;
        margin-top: 0;
        margin-bottom: 10px;
    }
}
body.blog{
    .card.archive-card.grid-item:nth-of-type(1) {
    width: 100%!important;
    z-index: 9;
    .featured-img{
        height:275px;
        .featured-img-link{
            display:block;
            height:100%;
            width:100%
        }
    }
    h2{
        font-size:32px;
        font-family:'Helvetica Neue condensed',helvetica,arial,sans-serif;
        text-transform:uppercase;
        line-height: 1.1;
        a {
            color:$uhc-med-blue;
        }
    }
    &:before{
        content: 'Featured';
        background-color: #7670e1;
        color: #f1eee7;
        position: absolute;
        padding: 2% 4%;
        left: 0;
        top: 0;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 4px;
        font-size:14px;
        }
    }
    .ind-blog{
        margin-top: -140px;
        h2{
        font-family:'Helvetica Neue ',helvetica,arial,sans-serif;
        text-transform:none;
        }
          @media #{$medium-and-down} {
           margin-top:0;
        }
    }
}
body.paged-2,body.paged-3,body.paged-4{
    .card.archive-card.grid-item:nth-of-type(1) {
    width: 31.333% !important;
     @media #{$medium-and-down} {
       width: 48% !important;
    }
    @media #{$small-and-down} {
       width: 100% !important;
    }
    .featured-img{
        height: 160px;
        .featured-img-link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
        }
    }
    h2{
        font-size: 20px;
        font-family:'Helvetica Neue',helvetica,arial,sans-serif;
        a{
            color:$uhc-blue;
            line-height:1;
            font-family: 'Helvetica Neue ',helvetica,arial,sans-serif;
            text-transform:none;
        }
    }
     &:before{
        content: none;
     }
    }
    .ind-blog{
        margin-top: 0px;
    }
}
.flex-blog{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap: wrap;
}
.ind-blog{
    img{
        max-width:100%;
    }
    .archive-card{
        margin-left: 0 !important;
        margin-right: 2%;
        width: 31.333%!important;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        @media #{$medium-and-down} {
            width: 48% !important;
        }
        @media #{$small-and-down} {
            width: 100% !important;
        }
        h2 {
            font-size: 20px;
            letter-spacing: 0;
            margin: 0 0 7px;
                a{
                    color:$uhc-blue;
                    text-decoration:none;
                    &:visited{
                        color:$uhc-blue;
                    }
                }
        }
        .readmore-archive{
            display:block;
            padding: 1% 3% 1% 5%;
            border-top:1px solid #e3e3e3;
            color: #545454;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-decoration:none;
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
            background-color: #f6f6f6;
            transition:.25s all ease-in-out;
            text-align: right;
            &:hover {
                opacity: 0.8;
                transition:.25s all ease-in-out;
            }
        }
    }
}
.featured-img{
        width: calc(100% + 2px);
        height: 160px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .featured-img-link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
    }
}
.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin:0;
            padding:0;
            li{
                list-style:none;
                transition:.25s all ease-in-out;
                background-color:#000;
                &:hover{
                    -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    -webkit-transform: translate(-2px,-2px);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2017/04/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(/wp-content/uploads/2019/02/uhc-er-header3c-1.jpg);
                    background-position:bottom;
                }
                a {
                    padding:8% 5%;
                    text-decoration:none;
                    display:block;
                    border: 1px solid #16375c;
                    font-family: 'Iowan Old Style Bold';
                    font-size:35px;
                    text-transform:uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode:multiply;
                    span{
                        font-weight:300;
                        font-size:13px;
                        display:block;
                        letter-spacing: 3px;
                        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
}
.post-sidebar h5 span {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    font-size: 27px;
}
.mywvuchart-card {
    padding: 0!important;
    margin-top: 2em!important;
    border-radius: 6px;
    margin-bottom: 6em!important;
    @media (min-width: 600px) {
        margin-bottom: 2em!important;
    }
    .sidebar-widget.mywvuchart {
        padding: 0!important;
        display: grid;
        a {
            display: block;
            background-color: $uhc-blue;
            color: white;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            font-size: 1.5em;
            padding: 0.5em 0;
            transition: 0.2s all;
            border-radius: 6px;
            &:hover {
                opacity: 0.9;
                transition: 0.2s all;
            }
            img {
                width: 50%;
                padding-top: 6px;
            }
        }
        .textwidget {
            padding: 0;
        }
        img {
            border-radius: 5px 5px 0 0;
            width: 100%;
        }
    }
    & > div:nth-of-type(2) {
        margin-top: -2em;
    }
    & > div:nth-of-type(3) {
        & > div {
            margin: 1em;
            border-radius: 6px;
        }
    }
}
//////////////////////////////
// WVUMyChart Slideshow
//////////////////////////////
ul#slides {
    position: relative;
    height: 7em;
    margin-bottom: 0;
    @media (min-width: 600px) {
        height: 6em;
    }
}
#slides .slide {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: 5em;
    padding: 0 2em!important;
    font-size: 0.75em;
    line-height: 1.3!important;
    border-bottom: none!important;
    margin-top: 2.5em;
    color: $uhc-blue;
    @media (min-width: 600px) {
        height: 4em;
        font-size: 18px;
    }
    &:before {
        content: "";
        top: 0;
        left: 0;
    }
    &:nth-child(2) {
        opacity: 0;
        -webkit-animation-delay: 6s;
        animation-delay: fade 6s;
        -moz-animation-delay: fade 6s;
        -o-animation-delay: fade 6s;
    }
    &:nth-child(3) {
        opacity: 0;
        -webkit-animation-delay: 12s;
        animation-delay: fade 12s;
        -moz-animation-delay: fade 12s;
        -o-animation-delay: fade 12s;
    }
    &:nth-child(4) {
        opacity: 0;
        -webkit-animation-delay: 18s;
        animation-delay: fade 18s;
        -moz-animation-delay: fade 18s;
        -o-animation-delay: fade 18s;
    }
    & > div {
            width: 45px;
            height: 45px;
            float: left;
            margin-right: .5em;
            margin-bottom: 6em;
        .icon {
            height: 100%;
            width: fit-content;
        }
    }
}
.fade {
-webkit-animation: fade 24s infinite;
animation: fade 24s infinite;
-moz-animation: fade 24s infinite;
-o-animation: fade 24s infinite;
}
@-webkit-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@-moz-keyframes fade{
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
    }
@-o-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
.article-body ul li ul {
	margin-top: 1em!important;
	margin-bottom: 0;
}
.article-body ul li ul li::before {
	content: "◻"!important;
}
ul li {
	line-height: 1.5!important;
	break-inside: avoid-column;
}
@media (max-width: 768px) {
	.intro-p {
		font-size: 18px!important;
	}
}
.card.callout {
	margin-top: 64px!important;
}
img.logos {
    height: auto;
}
.uronav {
    max-height: 31.2em;
    margin: 0 auto;
    display: block;
}
.text-col-2 li {
	display: flex;
}
.dr-contact {
    margin-top: 1.5em;
    border-radius: 6px;
    & .title {
        border-radius: 6px 6px 0 0;
    }
}
@media (min-width: 500px) and (max-width: 992px) {
	.dr-contact {
    	display: block;
    	margin: 0 auto;
        max-width: 75%;
        margin-bottom: 1em;
    }
}
@media (max-width: 1200px) {
	.uronav {
	margin-top: 0;
}
.page-heading .container p {
	line-height: 1.2;
}
}
@media (min-width: 992px) {
	.text-col-2 {
		-webkit-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 40px;
		column-gap: 40px;
	}
}
ul.text-col-2 {
    padding-left: 0.6em;
    li {
        line-height: 1.2;
        padding-bottom: 12px;
        position: relative;
        padding-left: 18px;
        border-bottom: 1px solid #d8d8d8;
        &:before {
            position: absolute;
            font-family: FontAwesome;
            left: -1rem;
            font-size: 16px;
            top: 2px;
            content: $bulletcontent;
            color: $bulletcolor;
        }
    }
}
.inline-image {
    margin: 2em auto 3em;
    box-shadow: 0px 20px 50px -15px rgba(0,0,0,.4);
    display:block;
}
.post-sidebar{
    position:relative;
    z-index: 1000;
    .link-wrap{
        position: absolute;
    width: 100%;
    height: 100%;
    z-index:99999;
    }
}
body.home{
    .page-heading{
        background-color:rgba(0,92,181,0);
        padding:10.5% 0;
    }
    .content-area h2 {
        color: $uhc-blue;
    }
}
blockquote.card.callout {
    @media (max-width: 600px) {
        padding-top: 48px!important;
        padding-bottom: 24px!important;
    }
    p {
        margin: 0;
        width: 100%;
    }
}
blockquote.card.callout img {
    max-width: 120px;
    margin: 0 auto;
    height: auto;
    display: block;
    margin-bottom: 1em;
    @media (min-width: 600px) {
        max-width: 120px;
        margin-right: 16px;
        float: left;
        height: auto;
        margin-bottom: 15em;
    }
    @media (min-width: 720px) {
        margin-bottom: 9em;
    }
}
.usa-nav.is-visible .usa-nav-link:hover {
    color: $uhc-blue!important;
    text-decoration: no-underline!important;
}
//////////////////////////////
// About
//////////////////////////////
.about-page-container {
    .cancer-patients.first {
        @media (min-width: 1300px) {
            display: inline-block;
            background-color: transparent;
            margin-top: -2em;
        }
        @media (min-width: 2000px) {
            margin-top: -4em;
        }
        h2 {
            margin-bottom: 0em;
        }
        h2 + p {
            color: $uhc-blue;
            line-height: 1.5;
            font-size: 1.3em;
            letter-spacing: .008em;
            margin: 1em 0;
            margin-top: 0.5em;
            @media (min-width: 992px ) {
                width: 80%;
                font-size: 1.1em;
                line-height: 1.4;
            }
        }
    }
    padding-bottom: 2em;
    .gallery-row > .col {
        // min-height: 330px!important;
        // height: 355px!important;
    }
    & > .row h2 {
        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
        font-size: 3em!important;
        color: $uhc-blue;
        font-weight: 400!important;
        text-transform: capitalize!important;
        letter-spacing: 0px;
        @media (max-width: $breakpoint-phone) {
            font-size: 2em!important;
            line-height: 1!important;
        }
    }
    .m9 > .m6 {
        @media (min-width: 992px) {
            padding-right: 1em;
        }
        margin-bottom: 20px;
    }
}
.about-content {
    margin-top: 6em;
    p {
        margin-top: 0;
        padding: 0 0.5em;
    }
}
.doc-card {
    display: flex;
    flex: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 6px;
    img.doc-headshot {
    	height: 100%;
        padding: 0.5em;
        display: inline-block;
        float: left;
        max-width: 120px;
        width: 100%;
    }
    .doc-blue {
        background-color: rgba(207, 233, 244, 0.6);
        width: 100%;
        height: 115px;
        position: relative;
        border-radius: 5px 5px 0 0;
	    h5 {
            font-size: 21px;
            font-family: 'Helvetica Neue';
            font-weight: 600;
            top: 0;
            color: $uhc-blue;
            bottom: 0;
            margin:0;
            line-height: 1.3;
            @media (min-width: 600px) {
                font-size: 23px;
            }
            span {
                font-size: 0.6em;
                font-weight: 600;
                color: $uhc-blue;
            }
		}
	}
	.bottom-doc {
	    max-width: 100%;
	    padding: 32px;
	    margin-bottom: 3em;
	    p:first-child {
    	    margin-top: 0px;
    	    width: 100%;
    	}
	}
	a {
        width: 90%;
        text-align: center;
        padding: 0.5em 0;
        background-color: $uhc-gold;
        text-decoration: none;
        transition: 0.2s all;
        color: $uhc-blue;
        border-radius: 6px;
        margin: 0 auto;
        margin-bottom: 1em;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        &:hover {
            opacity: 0.9;
            transition: 0.2s all;
            color:$uhc-blue;
        }
	}
}
.about-image {
    &.card {
        border-radius: 6px;
        overflow: hidden;
    }
    p {
        padding: 0.25em 1em 0em 1em!important;
    }
}
.cancer-patients-section {
    background-color: #a8d3e8;
    .cancer-patients {
        position: relative;
        margin-bottom: 3em;
        @media (min-width: 992px) {
            padding-right: 1.5em;
            margin-bottom: 0em;
        }
        img {
            position: absolute;
            top: -5em;
            left: 0;
            width: 80px;
            height: 80px;
            -webkit-filter: drop-shadow(0 0 4px rgba(0,0,0,.3));
            filter: drop-shadow(0 0 4px rgba(0,0,0,.3));
            @media (min-width: 992px) {
                width: 150px;
                height: 150px;
                top: -12em;
            }
            @media (min-width: 1200px) {
                top: -11em;
            }
        }
    }
    .navigators .m6 {
        @media (min-width: 768px) {
            padding-right: 1em;
        }
    }
}
.navigator-card {
    background-color: lighten(#a8d3e8, 20%);
    border-radius: 6px;
    padding: 1em;
    margin-bottom: 1em;
    display: inline-block;
    text-align: center;
    width: 100%;
    box-shadow: $box-shadow-large;
    @media (min-width: 992px) {
        text-align: left;
        img {
            padding-right: 1.5em;
            filter: drop-shadow(0 0px 8px rgba(0,0,0,.1));
        }
        p {
            margin: 0;
        }
    }
    h5 {
        font-size: 21px;
        font-weight: 700;
        color: $uhc-blue;
    }
    a {
        font-size: 20px;
        color: $uhc-blue;
        font-weight: 700;
        text-decoration: none;
        margin-top: 1em;
        display: block;
        &:hover {
            color: lighten($uhc-blue, 10%);
        }
    }
}
.cancer-offices-section {
    padding: 4em 0;
    ul {
        list-style: none;
        padding: 0;
    }
    .office-numbers {
        margin: 0 auto;
        a {
            font-size: 20px;
            color: $uhc-blue;
            text-decoration: none;
            white-space: nowrap;
            &:hover {
                color: lighten($uhc-blue, 10%);
            }
        }
    }
}
//////////////////////////////
// Nurse Navigators Page
//////////////////////////////
.navigators-container {
    padding-bottom: 2em;
    h2 {
        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
        font-size: 3em!important;
        color: $uhc-blue;
        font-weight: 400!important;
        text-transform: capitalize!important;
        letter-spacing: 0px;
        margin-bottom: 1em;
        @media (max-width: $breakpoint-phone) {
            font-size: 2em!important;
            line-height: 1!important;
        }
    }
    .statement {
        margin-bottom: 1.5em;
        @media (min-width: 992px) {
            min-height: 16em;
        }
        &:after {
            content: "";
            width: 40%;
            height: 4px;
            background-color: darken(#a8d3e8, 20%);
            display: block;
        }
        p {
            font-size: 24px;
            color: $uhc-blue;
        }
    }
    .m6 {
        @media (min-width: 992px) {
            padding-right: 1.5em;
        }
    }
}
.nurse-navigators-section, .cancer-patients-section {
    background-color: #a8d3e8;
    padding-bottom: 2em;
    .nurse-navigators-container, .about-page-container {
        .row {
            position: relative;
            .nurse-navigators-header {
                    margin-top: -90px;
                img {
                    position: absolute;
                    top: -5em;
                    left: 0;
                    width: 80px;
                    height: 80px;
                    -webkit-filter: drop-shadow(0 0 4px rgba(0,0,0,.3));
                    filter: drop-shadow(0 0 4px rgba(0,0,0,.3));
                    @media (min-width: 992px) {
                        width: 150px;
                        height: 150px;
                        top: -14em;
                    }
                }
                h2 {
                    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
                    font-size: 3em!important;
                    color: $uhc-blue;
                    font-weight: 400!important;
                    text-transform: capitalize!important;
                    letter-spacing: 0px;
                    margin: 1em 0;
                    margin-top: 100px;
                    @media (max-width: $breakpoint-phone) {
                        font-size: 2em!important;
                        line-height: 1!important;
                        margin-top: 3em!important;
                    }
                    @media (min-width: 992px) {
                        margin-top: 1em!important;
                    }
                }
            }
            .provider-container {
                margin-bottom: 0.5em;
                @media (min-width: 500px) {
                    display: inline-grid;
                }
                @media (min-width: 992px) {
                    padding-right: 0.5em;
                }
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    display: inline-block!important;
                }
            }
            .quote {
                background-color: $color-gray-lightest;
                border-radius: 6px;
                padding: 2em;
                font-size: 20px;
                box-shadow: $box-shadow-large;
                position: relative;
                @media (min-width: 992px) {
                    min-height: 18em;
                }
                @media (min-width: 1300px) {
                    min-height: 13em!important;
                }
                p {
                    margin: 0;
                    line-height: 1.5;
                    z-index: 1;
                    position: relative;
                    color: $uhc-blue;
                }
                &:after {
                    content: "";
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: rotate(45deg);
                    background-color: $color-gray-lightest;
                    z-index: 0;
                    margin: auto;
                }
            }
            .provider-info {
                text-align: center;
                & > div {
                    border-radius: 6px;
                    background-color: white;
                    max-width: 220px;
                    display: block;
                    margin: 0 auto;
                    & > div:first-of-type {
                        max-height: 320px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 6px 6px 0 0;
                        }
                    }
                    & > div:last-of-type {
                        padding: 1em;
                        padding-bottom: 0.25em;
                        min-height: 135px;
                        padding-left: 1.5em;
                        padding-right: 1.5em;
                        h4 {
                            font-weight: 700;
                            font-size: 1.15em;
                            line-height: 1.2;
                            word-break: break-word;
                        }
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
//////////////////////////////
// Subfooter Section
//////////////////////////////
.section-subfooter {
    background-size: cover;
    margin-bottom: 0;
    background-position: left;
    padding: 2em 0;
    background-color: rgba(239, 169, 4, 1);
    position: relative;
    overflow: hidden;
    .l6 {
        img {
            width: 75px;
            height: 75px;
            margin-bottom: 1em!important;
            filter: drop-shadow(2px 5px 7px rgba(0,0,0,0.3));
            @media (max-width: 992px) {
                margin: 0 auto;
                display: block;
            }
            & + p {
                margin: 0!important;
            }
        }
        @media (min-width: 600px) {
            padding-left: 1em;
        }
    }
    .subfooter-card {
        border-radius: 6px;
        overflow: hidden;
        max-width: 600px;
        margin: 0 auto;
        display: block;
        margin-bottom: 2em;
        a {
            text-decoration: none;
            padding: 1em;
            display: block;
            p {
                margin: 0;
            }
            span {
                color: #6b60dd!important;
                padding-bottom: 0.5em;
                strong {
                    font-size: 2em!important;
                    color: #6b60dd!important;
                    line-height: 1!important;
                    font-weight: 700!important;
                }
            }
            p:last-of-type {
                border-top: 1px solid $color-gray-light;
                padding-top: 0.5em;
            }
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    h2 {
        font-family: 'Helvetica Neue',Helvetica,sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.5px;
        text-transform: initial;
        color: $uhc-blue;
    }
    strong {
        letter-spacing: 0.25px;
        color: $uhc-blue;
    }
    ul {
        margin-bottom: 0;
        & > li {
            border-bottom: none;
            font-size: 20px;
            margin-bottom: 0;
            &:before {
                color: $uhc-blue;
            }
        }
    }
}
.gold-square {
    position: absolute;
    background-color: rgba(239, 169, 4, 0.89);
    height: 1409px;
    width: 1146px;
    transform: rotate(33.75deg);
    left: -37%;
    top: -120%;
    z-index: 1;
    opacity: 0.8;
    @media (min-width: 992px) {
        left: initial;
        right: 56%;
    }
}
//////////////////////////////
// New Styles from Old Site //
//////////////////////////////
.play-btn-mobile {
    padding: 5%!important;
    img {
    @media (max-width: 992px) {
        max-width: 75px;
        display: block;
        margin: -61px auto!important;
        position: relative;
        max-width: 75px!important;
        }
    }
}
img.cancer-icons, img.img-icon {
    @media (max-width: 992px) {
        max-width: 75px!important;
        width: 100%;
        height: auto;
    }
}
.flex {
    display: flex;
    @media (max-width: 1148px) {
    display: block;
    }
}
.gallery-row {
    padding: 4em 0 0;
    display: flex;
    flex-wrap: wrap;
    .m6 {
        @media only screen and (min-width: 767px) {
            width: 48.33%!important;
        }
        @media (min-width: 992px) {
            padding-right: 1em;
            margin-bottom: 0.5em;
        }
    }
    & > .col {
        margin-left: 0!important;
        display: flex;
        max-width: 400px;
        // height: 391px;
        // max-height: 391px;
        margin-bottom: 1em!important;
        @media (max-width: 992px) {
            justify-content: center;
            text-align: center;
            float: initial;
            margin-left: auto!important;
            display: block;
            margin: 0 auto;
            min-height: initial;
        }
    }
    .card {
        border-radius: 6px;
        overflow: hidden;
    }
}
img.res {
    width: 100%;
    height: auto;
}
//////////////////////////////
// Homepage Form
//////////////////////////////
[type=checkbox]:checked+label::before {
    background-color: $uhc-gold!important;
}
.screenings-section {
    #gform_1 {
        li {
            margin-top: 0;
            label {
                color: rgba(255, 255, 255, 0.8);
                margin-top: 0;
                font-size: 0.75em;
            }
            input {
                padding: 0.8em;
                background-color: rgba(255, 255, 255, 0.8)!important;
                transition:.25s all ease-in-out;
                border-radius: 6px;
                &:hover {
                    background-color: rgba(255, 255, 255, 1)!important;
                    transition:.25s all ease-in-out;
                }
                &:focus {
                    background-color: rgba(255, 255, 255, 1)!important;
                    transition:.25s all ease-in-out;
                }
            }
            @media (min-width: 600px) {
                width: 25%!important;
                float: left;
                clear: none!important;
                input {
                    width: 100%!important;
                    background-color: #fff;
                    display: block;
                }
            }
            &#field_1_6 {
                width: 100%!important;
                ul {
                    li {
                        width: 100%!important;
                        text-align: center;
                        color: white;
                        label {
                            visibility: visible;
                        }
                    }
                }
                & > label {
                    visibility: hidden;
                }
            }
        }
        .gform_footer {
            text-align: center;
        }
        .gfield_required {
            color: $uhc-gold!important;
        }
    }
}
@media print {
    body{
        background-color:#000!important;
        img{
            width:100%!important;
        }
        header{
            display:none!important;
        }
    }
}
.pagination li {
    height: auto;
    background-color: #f1aa00;
    display: inline-block;
    border-radius: 6px;
    text-align: center;
    vertical-align: top;
    height: 36px;
    .current {
        background-color: #fff;
        padding: 5px 14px 1px;
        display: block;
        border: 1px solid #f1aa00;
        font-size: 1.62rem;
        color: #f1aa00;
        height: 36px;
        border-radius: 6px;
    }
    a {
        color: #444;
        display: inline-block;
        font-size: 1.62rem;
        line-height: auto;
        padding: 0;
        display: block;
        padding: 6px 14px 2px;
        display: block;
        width: 100%;
            text-decoration: none;
    }
    .next, .prev {
        background-color: #fff;
        height: 39px;
    }
}
h2 {
    color: $uhc-blue;
}
.gold-header {
    color: $uhc-gold!important;
}
.newsletter-button {
    display: block;
    margin: 1em 0;
    padding: 0.5em 0.75em;
    text-align: center;
    text-decoration: none;
    background-color: $uhc-gold;
    border-radius: 6px;
    font-family: 'Helvetica Neue', helvetica, sans-serif;
    font-weight: bold;
    font-weight: 800;
    color: white;
    font-size: 1.5em;
}
aside.post-sidebar {
    padding: 0;
    & > .sidebar-widget:nth-of-type(1) {
        background-image: url(https://www.uhcspecialties.com/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        background-color: rgba(255, 255, 255, 0.4);
        background-blend-mode: lighten;
    }
    .textwidget {
        padding: 6px 28px;
        @media (max-width: 600px) {
           padding: 6px 16px;
        }
        .lead {
            font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
            font-size: 1.6em;
            line-height: 1.2;
            color: #757575;
            margin-bottom: 0!important;
            @media (min-width: 1200px) and (max-width: 1450px) {
                font-size: 1.4em;
                & + p {
                    font-size: 1em!important;
                    & + a {
                        font-size: 1.2em!important;
                    }
                }
            }
            & + p {
                font-size: 1.4em;
                margin: 0;
                line-height: 1.2em;
                & + a {
                    text-decoration: none;
                    color: $uhc-gold;
                    font-family: 'Helvetica Neue', Helvetica, sans-serif;
                    font-weight: bold;
                    font-weight: 700;
                    font-size: 1.6em;
                    margin-top: 0.5em;
                    display: block;
                    @media (min-width: $medium-screen) {
                        font-size: 1.8em;
                    }
                    &::after {
                        content: "";
                        background-repeat: no-repeat;
                        width: 1.6em;
                        height: 2.5em;
                        display: inline-block;
                        float: right;
                        margin-top: -0.9em;
                    }
                }
            }
        }
    }
}
.jumplink:target::before {
    content: "";
    display: block;
    height: 80px;
    margin: -80px 0 0;
}
.article-h1 ~ .col.s12 > .treatment-card {
    margin-bottom: 1em;
}

.priority-callout {
    display: flex;
    margin: 0 auto;
    width: 80%;
    border: 2px solid transparentize($uhc-gold, 0);
    border-radius: 6px;
    padding: 1.5em;
    margin-bottom: 5.5em;
    box-shadow: $box-shadow-large;
    @media (max-width: 880px) {
        flex-direction: column;
        a {
            margin-top: 1.5em;
            width: 100%;
            margin-bottom: 0;
        }
    }
    @media (min-width: 880px) {
        & > div {
            width: 80%;
        }
        p {
            padding-right: 2em;
        }
        a {
            margin: 0;
        }
    }
    b {
        margin-bottom: 0;
        font-size: 20px;
        display: block;
        line-height: 1.3;
        color: $uhc-blue;
    }
    p {
        margin-top: 10px;
        margin-bottom: 0;
        &:empty {
            display: none;
        }
    }
    a {
        height: fit-content;
        align-self: center;
    }
}