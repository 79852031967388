// custom styles for the site

.usa-header-basic {
    .usa-navbar {
        @media (max-width: #{$nav-width - 1}) {
            width: 100%;
        }
        .usa-logo {
            position: relative;
            display: flex;
            align-items: center;
            bottom: initial;
            & > a {
                flex: 1;
            }
        }
    }
    & > .usa-nav-container {
        display: flex;
        justify-content: space-between;
        background-color: white;
        // overflow: hidden;
        @media (min-width: $nav-width) {
            &:after {
                content: "";
                display: none;
            }
        }
    }
}

.usa-nav.is-visible {
    width: 30rem;
}

nav.usa-nav {
    @media (min-width: $nav-width) {
        display: inline-flex;
        align-items: center;
        .usa-nav-primary {
            margin-top: 0;
            display: flex;
            flex: 1;
            align-items: center;
            & > li {
                // 3 Styles below make it full navbar height
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                min-width: 120px;
                & > a, button {
                    display: inline-flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    transform: skew(0deg, 0deg);
                    flex: 1;
                    padding: 16px 20px;
                    span {
                        transform: skew(33.75deg, 0deg);
                        padding-bottom: 0!important;
                    }
                }
                & > button {
                    background: none!important;
                    span {
                        position: relative;
                        &:after {
                            content: "";
                            background-image: url(./dist/img/angle-arrow-down.svg);
                            position: absolute;
                            right: 0em;
                            width: 9px;
                            height: 100%;
                            top: 0;
                            bottom: 0;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            transition: 0.15s ease-in-out;
                        }
                    }
                    &[aria-expanded=true] {
                        span:after {
                            opacity: 0;
                        }
                    }
                }
                ul.usa-nav-submenu {
                    display: inline-flex;
                    flex-wrap: wrap;
                    top: $navbar-height!important;
                    li {
                        flex: 1;
                        flex-basis: 100%;
                        display: inline-flex;
                        align-items: center;
                        padding: 0;
                        a {
                            transform: skew(33.75deg, 0deg);
                            flex: 1;
                            padding: 16px 24px;
                        }
                    }
                }
            }
        }
    }
}

// homepage styles
.home {
    .page-heading {
        .container {
            max-width: 1600px;
        }
    }

    .logos {
        padding: 25px 0px 40px;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $medium-screen) {
            padding: 15px 0px;
        }
        .col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 0 auto;
        }
        img {
            @media (max-width: #{ $medium-screen - 1}) {
                align-self: center !important;
            }
        }
    }
}